<template>
  <div class="row">
    <div class="col-12">
      <div class="card border border-primary">
        <h5 class="card-header">
          Subjects
        </h5>
        <div class="card-body">
          <p class="card-text"></p>
          <div class="col-sm-6">
            <b-button v-b-modal.add-subject variant="primary">
              New Subject <i class="bx bxs-plus-square"></i>
            </b-button>

            <b-modal id="add-subject" size="xl" scrollable title="Add Subject">
              <div class="col-lg-12">
                <div class="card">
                  <div class="card-body">

                    <b-form>
                      <div class="row">
                        <div class="col-lg-5">

                          <div class="col justify-content-center">
                            <img
                              alt="photo-avatar"
                              class="rounded me-2"
                              data-holder-rendered="true"
                              src="@/assets/images/photo-avatar.png"

                            />
                          </div>
                          <label for="photo">Photo
                            <input id="photo" class="form-control"
                                   type="file"/>
                          </label>
                          <br>
                          <b-form-group class="mb-3" label="Subject Type" label-cols-sm="3"
                                        label-for="type">
                            <b-form-select id="type" v-model="subject.type"
                                           :options="options.type"></b-form-select>
                          </b-form-group>

                          <b-form-group class="mb-3" label="Armed" label-cols-sm="3"
                                        label-for="armed">
                            <b-form-select id="armed" v-model="subject.armed"
                                           :options="options.armed"></b-form-select>
                          </b-form-group>

                          <b-form-group class="mb-3" label="Suicidal" label-cols-sm="3"
                                        label-for="suicidal">
                            <b-form-select id="suicidal" v-model="subject.suicidal"
                                           :options="options.suicidal"></b-form-select>
                          </b-form-group>

                          <b-form-group class="mb-3" label="Status" label-cols-sm="3"
                                        label-for="status1">
                            <b-form-select id="status1" v-model="subject.status"
                                           :options="options.status"></b-form-select>
                          </b-form-group>

                          <b-form-group class="mb-3" label="Disposition" label-cols-sm="3"
                                        label-for="disposition">
                            <b-form-select id="disposition" v-model="subject.disposition"
                                           :options="options.disposition"></b-form-select>
                          </b-form-group>
                        </div>

                        <div class="col-lg-6 offset-lg-1">
                          <b-form-group class="mb-3" label="First name" label-cols-sm="4"
                                        label-for="firstname">
                            <b-form-input id="firstname" v-model="subject.firstname"
                                          type="text"></b-form-input>
                          </b-form-group>

                          <b-form-group class="mb-3" label="Last name" label-cols-sm="4"
                                        label-for="lastname">
                            <b-form-input id="lastname" v-model="subject.lastname"
                                          type="text"></b-form-input>
                          </b-form-group>

                          <b-form-group class="mb-3" label="Address" label-cols-sm="4"
                                        label-for="address">
                            <b-form-input id="address" v-model="subject.address"
                                          type="text"></b-form-input>
                          </b-form-group>

                          <b-form-group class="mb-3" label="Phone" label-cols-sm="4"
                                        label-for="phone">
                            <b-form-input id="phone" v-model="subject.phone"
                                          type="text"></b-form-input>
                          </b-form-group>

                          <b-form-group class="mb-3" label="DOB" label-cols-sm="4"
                                        label-for="dob">
                            <b-form-input id="dob" v-model="subject.dob"
                                          type="text"></b-form-input>
                          </b-form-group>

                          <b-form-group class="mb-3" label="SSN" label-cols-sm="4"
                                        label-for="ssn">
                            <b-form-input id="ssn" v-model="subject.ssn"
                                          type="text"></b-form-input>
                          </b-form-group>

                          <b-form-group class="mb-3" label="Race" label-cols-sm="4"
                                        label-for="race">
                            <b-form-select id="race" v-model="subject.race"
                                           :options="options.race"></b-form-select>
                          </b-form-group>

                          <b-form-group class="mb-3" label="Sex" label-cols-sm="4"
                                        label-for="sex">
                            <b-form-select id="sex" v-model="subject.sex"
                                           :options="options.sex"></b-form-select>
                          </b-form-group>

                          <b-form-group class="mb-3" label="Height" label-cols-sm="4"
                                        label-for="height">
                            <b-form-input id="height" v-model="subject.height"
                                          type="text"></b-form-input>
                          </b-form-group>

                          <b-form-group class="mb-3" label="Weight" label-cols-sm="4"
                                        label-for="weight">
                            <b-form-input id="weight" v-model="subject.weight"
                                          type="text"></b-form-input>
                          </b-form-group>

                          <b-form-group class="mb-3" label="Hair" label-cols-sm="4"
                                        label-for="hair">
                            <b-form-select id="race" v-model="subject.hair"
                                           :options="options.hair"></b-form-select>
                          </b-form-group>

                          <b-form-group class="mb-3" label="Eyes" label-cols-sm="4"
                                        label-for="eyes">
                            <b-form-select id="eyes" v-model="subject.eyes"
                                           :options="options.eyes"></b-form-select>
                          </b-form-group>

                          <b-form-group class="mb-3" label="Facial Hair" label-cols-sm="4"
                                        label-for="facial_hair">
                            <b-form-select id="facial_hair" v-model="subject.facial_hair"
                                           :options="options.facial_hair"></b-form-select>
                          </b-form-group>

                          <b-form-group class="mb-3" label="Build / Stature" label-cols-sm="4"
                                        label-for="build_or_stature">
                            <b-form-input id="build_or_stature" v-model="subject.build_or_stature"
                                          type="text"></b-form-input>
                          </b-form-group>

                          <b-form-group class="mb-3" label="Language" label-cols-sm="4"
                                        label-for="language">
                            <b-form-input id="language" v-model="subject.language"
                                          type="text"></b-form-input>
                          </b-form-group>

                          <b-form-group class="mb-3" label="Employer / School" label-cols-sm="4"
                                        label-for="employer_or_school">
                            <b-form-input id="employer_or_school"
                                          v-model="subject.employer_or_school"
                                          type="text"></b-form-input>
                          </b-form-group>

                          <b-form-group class="mb-3" label="Special Training" label-cols-sm="4"
                                        label-for="special_training">
                            <b-form-input id="special_training" v-model="subject.special_training"
                                          type="text"></b-form-input>
                          </b-form-group>

                          <b-form-group class="mb-3" label="Other Info" label-cols-sm="4"
                                        label-for="other_info">
                            <b-form-input id="other_info" v-model="subject.other_info"
                                          type="text"></b-form-input>
                          </b-form-group>

                          <div class="row justify-content-end">
                            <div class="col-sm-9">
                              <div>
                                <b-button variant="primary">Submit</b-button>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </b-form>
                  </div>
                </div>
              </div>

            </b-modal>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IncidentSubjects',
  data() {
    return {
      subject: {
        type: null,
        armed: null,
        suicidal: null,
        status: null,
        disposition: null,
        firstname: '',
        lastname: '',
        address: '',
        phone: '',
        dob: '',
        ssn: '',
        race: null,
        sex: null,
        height: '',
        weight: '',
        hair: null,
        eyes: null,
        facial_hair: null,
        build_or_stature: '',
        language: '',
        employer_or_school: '',
        special_training: '',
        other_info: '',
      },
      // Options for Input selects
      options: {
        type: ['Other', 'Victim', 'Suspect', 'Missing Person', 'Runaway', 'Person of Interest',
          'Hostage'],
        armed: ['Unknown', 'Yes', 'No'],
        suicidal: ['Unknown', 'Yes', 'No'],
        status: ['At Large', 'In Custody', 'Detained'],
        disposition: ['Unknown', 'Arrested', 'Located - Released', 'Located - Released to Medical',
          'Located - Deceased', 'Not Located'],
        race: ['Unknown', 'Caucasian', 'African-American', 'Native-American', 'Hispanic', 'Other'],
        sex: ['Unknown', 'Male', 'Female'],
        hair: ['Unknown', 'Brown', 'Black', 'White', 'Sandy', 'Gray', 'Red/Auburn',
          'Blonde/Strawberry', 'Bald/Balding', 'Other'],
        eyes: ['Unknown', 'Blue', 'Brown', 'Gray', 'Green', 'Hazel', 'Multicolored', 'Other'],
        facial_hair: ['None', 'Stubble', 'Full Beard', 'Goatee', 'Mustache', 'Other'],
      },
    };
  },
};
</script>

<style scoped>

.mb-3 {
  max-height: 25px;
}

</style>
