<template>

  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Checklists</h4>
            <p class="card-title-desc">
              Note: This section may only be used to add and remove checklists from the incident.
              <br>
              <b>To check or uncheck a list item please visit the Command Board.</b>
            </p>
            <br>
            <div class="form-check" v-for="checklist in clientChecklists" :key="checklist.id">
              <label class="form-check-label col-12 mb-2" for="checkbox">
                <input class="form-check-input checkbox" type="checkbox"
                       v-model="incidentChecklists" :value="checklist.id"
                       :id="`checkbox-${checklist.id}`">
                <div class="accordion">
                  <div class="accordion-item">
                    <div class="accordion-header" :id="`heading${checklist.id}`">
                      <button class="btn accordion-button collapsed p-2" type="button"
                              data-bs-toggle="collapse"
                              :data-bs-target="`#collapse${checklist.id}`">
                        {{ checklist.value }}
                      </button>
                      <div class="accordion-collapse collapse"
                           :id="`collapse${checklist.id}`">
                        <div class="accordion-body">
                          <p v-if="checklist.description">
                            Description: {{ checklist.description }}
                          </p>
                          <div v-if="checklist.items.length">
                            <div v-for="item in checklist.items" :key="item.id"
                                 class="form-check">
                              <label class="form-check-label" for="flexCheckChecked">
                                <input class="form-check-input" type="checkbox"
                                       id="flexCheckChecked" disabled
                                       v-model="incidentChecklistItems"
                                       :value="item.id">
                                <b>{{ item.item }}</b><!--{{ getModifiedBy(item.id) }}-->
                              </label>
                            </div>
                          </div>
                          <div v-if="!checklist.items.length"
                               class="alert alert-warning" role="alert">
                            This checklist doesn't have any items!
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  authComputed,
  personnelComputed,
  personnelMethods,
  incidentsComputed,
  checklistsComputed,
  checklistsMethods,
} from '@/state/helpers';

export default {
  name: 'IncidentChecklists',
  data() {
    return {
      incidentChecklists: [],
      incidentChecklistItems: [],
      clientChecklists: null,
    };
  },
  methods: {
    ...personnelMethods,
    ...checklistsMethods,
    loadIncidentChecklistsData() {
      // Get checklists and items from the current incident object
      this.incidentChecklists = []; // Clean from (if) previous incident.
      this.getCurrentIncident.checklist_assignments.forEach((checklist) => {
        this.incidentChecklists.push(checklist.checklist_id);
      });
      this.incidentChecklistItems = []; // Clean from (if) previous incident.
      this.getCurrentIncident.checklist_item_assignments.forEach((checklist_item) => {
        if (checklist_item.checked) {
          this.incidentChecklistItems.push(checklist_item.item_id);
        }
      });
      this.saveCurrentIncidentChecklists(this.incidentChecklists);
    },
    getModifiedBy(item_id) {
      if (!this.incidentChecklistItems.includes(item_id) || !this.getClientPersonnel) {
        return '';
      }
      const item_info = this.getCurrentIncident.checklist_item_assignments
        .find((item) => item.item_id === item_id);
      const user = this.getClientPersonnel.find((person) => person.id === item_info.last_mod_by);
      const localDate = new Date(item_info.last_mod);
      return ` - Checked by ${user.first_name} ${user.last_name} on ${localDate.toLocaleString()}`;
    },
  },
  computed: {
    ...authComputed,
    ...personnelComputed,
    ...incidentsComputed,
    ...checklistsComputed,
  },
  async mounted() {
    // Load personnel to show who checked the items.
    await this.loadClientPersonnel({
      user: JSON.parse(this.currentUser),
    });

    this.loadIncidentChecklistsData();

    // Retrieve Client Active Checklists and Checklist Items
    if (!this.getClientChecklists) {
      await this.loadClientChecklists({
        user: JSON.parse(this.currentUser),
      });
    }
    this.clientChecklists = structuredClone(this.getClientChecklists);
  },
  watch: {
    incidentChecklists(newValue) {
      this.saveNewIncidentChecklists(newValue);
    },
  },
};

</script>

<style scoped>

.checkbox {
  margin-top: 10px;
  width: 16px;
  height: 16px;
}

input:checked[disabled] {
  opacity: 1;
  background-color: green;
}

/* Give format to item and 'Checked by' legend */
label b {
  font-weight: 500;
}

.form-check-label {
  font-weight: 400;
}

</style>
