<template>
  <l-map
    :center="[getCurrentIncident.coordinates.points.latitude,
                  getCurrentIncident.coordinates.points.longitude]"
    :options="mapOptions"
    :zoom="zoom"
    style="height: 600px; width: 100%"
  >
    <l-tile-layer :attribution="attribution" :url="url"/>
    <l-marker :lat-lng="[getCurrentIncident.coordinates.points.latitude,
                  getCurrentIncident.coordinates.points.longitude]">
      <l-icon>
        <img alt="icon"
             :src="require(`@/assets/images/incident/${getCurrentIncident.map_icon}`)">
      </l-icon>
    </l-marker>
  </l-map>
</template>

<script>
import {
  LMap, LTileLayer, LMarker, LIcon,
} from '@vue-leaflet/vue-leaflet';
import { incidentsComputed } from '@/state/helpers';

export default {
  name: 'IncidentMap',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
  },
  data() {
    return {
      zoom: 15,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,
        zoomControl: false,
      },
      showMap: true,
    };
  },
  computed: {
    ...incidentsComputed,
  },
};
</script>

<style scoped>

</style>
