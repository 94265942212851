<template>
  <div class="row">
    <div class="col-12">
      <div class="card border border-primary">
        <h4 class="card-header">
          eICS Forms
        </h4>
        <div class="card-body">
          <div class="col-sm-6">
            <br>
            <br>
            <br>
            <br>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IncidentEics',
};
</script>

<style scoped>

</style>
