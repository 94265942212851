<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Incident Files</h4>
            <br>
            <div class="col-md-6">
              <label class="form-label" for="incident-file">
                Upload File
                <input id="file" class="form-control" type="file" ref="file"
                       @change="onChangeFileUpload()"/>
              </label>
              <b-button class="btn-label m-2" variant="success" @click="submitFormUploadFile()">
                <i class="bx bx-upload label-icon"></i>Upload File
              </b-button>
            </div>
            <br>
            <table v-if="getIncidentFiles?.length"
                   class="table align-middle table-nowrap table-hover">
              <thead class="table-light">
              <tr>
                <th class="align-middle">
                  Quick Actions
                </th>
                <th class="align-middle">Name</th>
                <th class="align-middle">File Type</th>
                <th class="align-middle">Date/Time</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="file in getIncidentFiles" :key="file.id" class="files-table">
                <td>
                  <b-button
                    v-b-tooltip.hover
                    variant="danger"
                    size="sm"
                    title="Delete File"
                    @click="deleteIncidentFile(file.id)">
                    <i class="fa-solid fa-xmark fa-xl"></i>
                  </b-button>
                  <b-button
                    v-b-modal.modal-view-file
                    v-b-tooltip.hover
                    variant="primary"
                    size="sm"
                    title="View File"
                    :disabled="!browserViewable(file)"
                    @click="viewFile(file)">
                    <i class="fa-sharp fa-light fa-eye fa-xl"></i>
                  </b-button>
                  <b-button
                    v-b-tooltip.hover
                    variant="primary"
                    size="sm"
                    title="Download"
                    @click="downloadFile(file)">
                    <i class="fa-solid fa-download fa-xl"></i>
                  </b-button>
                  <!-- TODO Switch to B-Button and fix variant for button class -->
                  <button
                    class="btn btn-sm" title="Share on Portal"
                          :class="{ 'btn-warning': file.shared_portal,
                           'btn-secondary': !file.shared_portal, }"
                          @click="toggleSharedPortalIncidentFile(file.id)">
                    <i class="fa-solid fa-share-nodes fa-xl"></i>
                  </button>
                </td>
                <td>
                  <b-button
                    v-b-tooltip.hover
                    variant="primary"
                    size="sm"
                    title="Edit Filename"
                    @click="editNameIncidentFile({id: file.id, name: file.file_name})">
                    <i class="fa-solid fa-pencil fa-xl"></i>
                  </b-button>
                  <span>{{ file.file_name }}</span>
                </td>
                <td>{{ getFileType(file.alias) }}</td>
                <td>{{ formatDate(file.client_date) }}</td>
              </tr>
              </tbody>
            </table>
            <div v-if="!getIncidentFiles?.length" class="alert alert-warning">
              This incident doesn't have any files!
            </div>
            <div>
              <b-modal id="modal-view-file" size="xl" centered title="View File">
                <div class="row">
                  <div v-if="!fileIsBrowserViewable" class="alert alert-warning">
                    <h5 class="text-center">
                      This file can't be opened in the browser!<br>
                      Please Download the file to see it.
                    </h5>
                  </div>
                  <img v-else id="img-file" :src="viewFileURL" alt="">
                </div>
              </b-modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {
  authComputed,
  filesComputed,
  filesMethods,
} from '@/state/helpers';

import { allowedFilesToUpload, browserViewableFiles } from '@/components/widgets/filesUtils';

/**
 * Incident Files Tab component
 */
export default {
  name: 'IncidentFiles',
  data() {
    return {
      incidentId: 0,
      incidentFiles: [],
      viewFileURL: '',
      fileIsBrowserViewable: null,
      fileToUpload: null,
      fileHeader: '',
    };
  },
  computed: {
    ...authComputed,
    ...filesComputed,
  },
  methods: {
    ...filesMethods,
    getFileType(alias) {
      const file_extension = alias.split('.').pop();
      const file_type = allowedFilesToUpload
        .find((fileType) => fileType.extension === file_extension);
      if (!file_type) {
        return 'Not Allowed / No Type';
      }
      return file_type.kind;
    },
    viewFile(file) {
      this.viewFileURL = '';
      // Check if this file can be opened in the browser
      const file_extension = file.alias.split('.').pop();
      if (!browserViewableFiles.includes(file_extension)) {
        this.fileIsBrowserViewable = false;
      } else {
        this.fileIsBrowserViewable = true;

        this.getIncidentFileUrl({
          user: JSON.parse(this.currentUser),
          incident_id: this.incident_id,
          file,
        }).then((url) => {
          this.viewFileURL = url;
        });
      }
    },
    downloadFile(file) {
      this.downloadIncidentFile({
        user: JSON.parse(this.currentUser),
        incident_id: this.incident_id,
        file,
      });
    },
    formatDate(date) {
      const formattedDate = date.replace('T', ' ');
      return formattedDate.split('.')[0];
    },
    submitFormUploadFile() {
      const formData = new FormData();
      formData.append('file', this.fileToUpload);
      this.uploadIncidentFile({
        user: JSON.parse(this.currentUser),
        incident_id: this.incident_id,
        formData,
        fileHeader: this.fileHeader,
      });

      // Clear file input form after submit
      this.fileToUpload = null;
      this.$refs.file.value = null;
    },
    onChangeFileUpload() {
      // Get Header of the file
      [this.fileToUpload] = this.$refs.file.files;
      const fileReader = new FileReader();
      fileReader.onloadend = (e) => {
        const arr = (new Uint8Array(e.target.result)).subarray(0, 4);
        let header = '';
        for (let i = 0; i < arr.length; i += 1) {
          let tempStr = arr[i].toString(16);
          if (tempStr.length === 1) {
            tempStr = `0${tempStr}`;
          }
          header += tempStr;
        }
        this.fileHeader = header;
      };
      fileReader.readAsArrayBuffer(this.fileToUpload);
    },
    browserViewable(file) {
      const file_extension = file.alias.split('.').pop();
      return browserViewableFiles.includes(file_extension);
    },
  },
  created() {
    const { incidentId } = this.$route.params;
    this.incident_id = +incidentId;
  },
  async mounted() {
    this.cleanStateFiles();
    await this.loadIncidentFiles({
      user: JSON.parse(this.currentUser),
      incident_id: this.incident_id,
    });
  },
};
</script>

<style scoped>

.btn {
  margin-right: 10px;
}

#img-file {
  margin: auto;
  max-width: 1000px;
  width: 50%;
}

</style>
