<template>
  <div class="row">
    <div class="col-12">
      <div class="card border border-primary">
        <h4 class="card-header">
          Log
        </h4>
        <div class="card-body">
          <b-button variant="success">Export to CSV</b-button>
          <br>
          <br>
          <b-table striped hover :items="logs"></b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IncidentLog',
  data() {
    return {
      // Test data
      logs: [
        {
          date_time: '2023-01-25 13:07:15',
          user: 'John Doe',
          action: 'Item [test] unchecked for checklist [Test list Update Date 6]',
        },
        {
          date_time: '2023-01-25 13:07:15',
          user: 'John Doe',
          action: 'Note: add some note for testing',
        },
        {
          date_time: '2023-01-24 11:25:08',
          user: 'John Doe',
          action: 'Task Created - [Type: Check Point, Call Sign: Check Point]',
        },
        {
          date_time: '2023-01-23 18:11:39',
          user: 'John Doe',
          action: 'Area added - test area',
        },
      ],
    };
  },
};
</script>

<style scoped>

</style>
