<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-10">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Incident Notes</h4>
            <p class="text-center">
              <b>Notice: (This is a view-only area for notes from the command board)</b>
            </p>
            <br>
            <div class="card border mb-2"
                 v-for="note in getIncidentNotes" :key="note.id">
              <h6 class="card-header">
                {{ note.from_name }} - {{ note.date_string }} ({{ note.time_zone }})
              </h6>
              <div class="card-body pb-2 pt-2">
                <p v-html="note.text"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  authComputed,
  notesComputed,
  notesMethods,
} from '@/state/helpers';

/**
 * Incident Notes Tab component
 */
export default {
  name: 'IncidentNotes',
  data() {
    return {
      incident_id: null,
    };
  },
  computed: {
    ...authComputed,
    ...notesComputed,
  },
  methods: {
    ...notesMethods,
  },
  created() {
    const { incidentId } = this.$route.params;
    this.incident_id = +incidentId;
  },
  async mounted() {
    await this.loadIncidentNotes({
      user: JSON.parse(this.currentUser),
      incident_id: this.incident_id,
    });
  },
};
</script>

<style scoped>

</style>
