<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Tags</h4>
            <br>
            <b-row>
              <b-col>
                <h5>Current Tags [Click to Remove]</h5>
                <div class="button-items">
                  <b-button v-for="tag in incidentTags" :key="tag.id" variant="primary"
                            @click="moveTag(tag.id)"> {{ tag.value }}
                  </b-button>
                </div>
              </b-col>
              <b-col>
                <h5>Available Tags [Click to Add]</h5>
                <div class="button-items">
                  <b-button v-for="tag in availableTags" :key="tag.id" variant="secondary"
                            @click="moveTag(tag.id)"> {{ tag.value }}
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  authComputed,
  incidentsComputed,
  tagsMethods,
  tagsComputed,
} from '@/state/helpers';

/**
 * Incident Tags Tab component
 */
export default {
  name: 'IncidentTags',
  data() {
    return {
      incidentTags: [],
      availableTags: [],
    };
  },
  computed: {
    ...authComputed,
    ...incidentsComputed,
    ...tagsComputed,
  },
  methods: {
    ...tagsMethods,
    sortByValue(arr) {
      return arr.sort((a, b) => (a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1));
      // this.incidentTags.sort((a, b) => (a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1));
    },
    loadIncidentTagsData() {
      // Get tags from the current incident object
      this.incidentTags = this.sortByValue(structuredClone(this.getCurrentIncident.tags));
      // Save tags ids as Incident Current Tags to Vuex store
      this.setIncidentCurrentTags(this.incidentTags.map((a) => a.id));
    },
    moveTag(id) {
      const tag = this.getClientTags.find((tag) => tag.id === id);
      if (this.incidentTags.find((tag) => tag.id === id)) {
        this.incidentTags = this.incidentTags.filter((tag) => tag.id !== id);
        this.availableTags.push(tag);
      } else {
        this.availableTags = this.availableTags.filter((tag) => tag.id !== id);
        this.incidentTags.push(tag);
      }
      this.availableTags = this.sortByValue(this.availableTags);
      this.incidentTags = this.sortByValue(this.incidentTags);
      // Save Incident New Tags
      this.setIncidentNewTags(this.incidentTags.map((a) => a.id));
    },
  },
  async mounted() {
    await this.loadClientTags({
      user: JSON.parse(this.currentUser),
    });
    this.loadIncidentTagsData();
    this.availableTags = this.getClientTags.filter((tag) => !this
      .incidentTags.map((tag) => tag.id).includes(tag.id));
    // Save Incident Tags IDs at Vuex Store
    this.setIncidentNewTags(this.incidentTags.map((a) => a.id));
  },
};
</script>

<style scoped>

</style>
