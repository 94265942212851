<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-if="basicData?.id">
            <b-form class="form-horizontal">
              <b-form-group class="mb-3" label="Map Icon" label-cols-lg="3">
                <b-dropdown v-model="basicData.map_icon"
                            dropright text="Select Icon >" variant="info" class="m-2">
                  <div class="icons">
                    <div v-for="(icons_group, category) in icons" :key="category">
                      <span class="mx-3 my-3">{{ category.toUpperCase() }}</span>
                      <hr>
                      <img v-for="icon in icons_group" :key="icon"
                           @click="selectIcon(icon)" @keydown="selectIcon(icon)"
                           :src="require(`@/assets/images/incident/${icon}`)"
                           class="rounded" :alt="`${icon}`">
                      <hr>
                    </div>
                  </div>
                </b-dropdown>
                <img :alt="`${basicData.map_icon}`"
                     :src="require(`@/assets/images/incident/${basicData.map_icon}`)"/>
              </b-form-group>

              <b-form-group class="mb-3" label="Name" label-cols-lg="3">
                <b-form-input v-model="basicData.name"></b-form-input>
              </b-form-group>

              <b-form-group class="mb-3" label="Incident ID" label-cols-lg="3">
                <b-form-input v-model="basicData.id" disabled="disabled"></b-form-input>
              </b-form-group>

              <b-form-group class="mb-3" label="Custom ID" label-cols-lg="3">
                <b-form-input v-model="basicData.custom_id"></b-form-input>
              </b-form-group>

              <b-form-group class="mb-3" label="High Priority" label-cols-lg="3">
                <div class="form-check-right form-check-danger pt-2">
                  <b-form-checkbox v-model="basicData.priority_incident">
                  </b-form-checkbox>
                </div>
              </b-form-group>

              <b-form-group class="mb-3" label="Location" label-cols-lg="3">
                <b-form-input v-model="basicData.location"></b-form-input>
              </b-form-group>

              <b-form-group class="mb-3" label="Latitude" label-cols-lg="3">
                <b-form-input disabled="disabled"
                              v-model="basicData.coordinates.points.latitude">
                </b-form-input>
              </b-form-group>

              <b-form-group class="mb-3" label="Longitude" label-cols-lg="3">
                <b-form-input disabled="disabled"
                              v-model="basicData.coordinates.points.longitude">
                </b-form-input>
              </b-form-group>

              <b-form-group class="mb-3" label="Date" label-cols-lg="3">
                <b-form-input type="date" v-model="basicData.date"></b-form-input>
              </b-form-group>

              <b-form-group class="mb-3" label="Time" label-cols-lg="3">
                <b-form-input type="time" v-model="basicData.time"></b-form-input>
              </b-form-group>

              <b-form-group class="mb-3" label="Time Zone" label-cols-lg="3">
                <b-form-select v-model="basicData.time_zone" :options="time_zones">
                </b-form-select>
              </b-form-group>

              <b-form-group class="mb-3" label="Description" label-cols-lg="3">
                <b-form-textarea v-model="basicData.description"
                                 placeholder="Add description..."></b-form-textarea>
              </b-form-group>

            </b-form>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </div>
</template>

<script>
import appConfig from '@/app.config.json';
import { incidentIcons } from '@/components/iconsDict';
import { authComputed, incidentsComputed, incidentsMethods } from '@/state/helpers';

/**
 * Incident Form component
 */
export default {
  page: {
    title: 'Incident Form',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      basicData: {
        id: null,
        map_icon: null,
        name: null,
        custom_id: null,
        priority_incident: null,
        location: null,
        coordinates: null,
        // latitude: null,
        // longitude: null,
        date: null,
        time: null,
        time_zone: null,
        description: null,
      },
      icons: incidentIcons,
      // Per backend these are the Time Zones allowed
      time_zones: ['EST', 'EDT', 'CST', 'MST', 'MDT', 'CDT', 'PST', 'PDT'],
    };
  },
  computed: {
    ...authComputed,
    ...incidentsComputed,
  },
  methods: {
    ...incidentsMethods,
    selectIcon(iconFilename) {
      this.basicData.map_icon = iconFilename;
    },
  },
  mounted() {
    // Load basic data fields into basicData object
    Object.keys(this.basicData).forEach((field) => {
      this.basicData[field] = this.getCurrentIncident[field];
    });
  },
  watch: {
    basicData: {
      handler(modifiedIncident) {
        // Create a localIncident object to update incident in state
        const localIncident = { ...this.getCurrentIncident };
        Object.keys(this.basicData).forEach((field) => {
          localIncident[field] = modifiedIncident[field];
        });
        this.updateCurrentIncident(localIncident);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>

.icons {
  width: 400px;
  height: 400px;
  overflow-y: auto;
}

.rounded:hover {
  cursor: pointer;
  background-color: gray;
}

</style>
