<template>
  <div>
    <h4>Admin Tab</h4>
  </div>

</template>

<script>
export default {
  name: 'IncidentAdmin',
};
</script>

<style scoped>

</style>
