<template>
  <div class="row">
    <div class="col-12">
      <div class="card border border-primary">
        <div class="card-body">
          <h5>CAD Triggers</h5>
          <br>
          <b-row class="mt-2">
            <b-col sm="3">
              <span>CAD Address</span>
            </b-col>
            <b-col sm="9">
              <b-form-textarea
                v-model="triggersData.cad_triggers"
                placeholder="Trigger: CAD Address"
                rows="4"
                max-rows="6"
              >
              </b-form-textarea>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col sm="3">
              <span>CAD Nature</span>
            </b-col>
            <b-col sm="9">
              <b-form-textarea
                v-model="triggersData.cad_nature"
                placeholder="Trigger: CAD Address"
                rows="4"
                max-rows="6"
              >
              </b-form-textarea>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="card border border-primary">
        <div class="card-body">
          <h5>Rave Triggers</h5>
          <br>
          <b-row class="mt-2">
            <b-col sm="3">
              <span>Rave Campus</span>
            </b-col>
            <b-col sm="9">
              <b-form-textarea
                v-model="triggersData.rave_campus"
                placeholder="Trigger: Rave Campus"
                rows="4"
                max-rows="6"
              >
              </b-form-textarea>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col sm="3">
              <span>Rave Building</span>
            </b-col>
            <b-col sm="9">
              <b-form-textarea
                v-model="triggersData.rave_building"
                placeholder="Trigger: Rave Building"
                rows="4"
                max-rows="6"
              >
              </b-form-textarea>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col sm="3">
              <span>Rave Type</span>
            </b-col>
            <b-col sm="4">
              <b-form-select
                v-model="triggersData.rave_type"
                :options="rave_types"
              >
              </b-form-select>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { incidentsComputed, incidentsMethods } from '@/state/helpers';

/**
 * Incident Triggers Tab component
 */
export default {
  name: 'IncidentTriggers',
  data() {
    return {
      loaded: false,
      triggersData: {
        cad_triggers: null,
        cad_nature: null,
        rave_campus: null,
        rave_building: null,
        rave_type: null,
      },
      rave_types: [
        { value: null, text: 'N/A' },
        { value: 'fire', text: 'Fire' },
        { value: 'police', text: 'Police' },
        { value: 'assailant', text: 'Active Assailant' },
        { value: 'medical', text: 'Medical' },
        { value: 'other', text: 'Other' },
      ],
    };
  },
  computed: {
    ...incidentsComputed,
  },
  methods: {
    ...incidentsMethods,
  },
  watch: {
    triggersData: {
      handler(modifiedTriggers) {
        const localIncident = { ...this.getCurrentIncident };
        Object.keys(this.triggersData).forEach((field) => {
          localIncident[field] = modifiedTriggers[field];
        });
        this.updateCurrentIncident(localIncident);
      },
      deep: true,
    },
  },
  mounted() {
    Object.keys(this.triggersData).forEach((field) => {
      this.triggersData[field] = this.getCurrentIncident[field];
    });
  },
};
</script>

<style scoped>

</style>
