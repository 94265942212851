<template>
  <Layout>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body" v-if="getCurrentIncident?.id">
            <div class="row">
              <div class="col-lg-5">
                <h4 class="card-title mb-4">{{ getCurrentIncident.name.toUpperCase() }}</h4>
                <p class="mb-2">
                  Location:
                  <span class="text-primary">{{ getCurrentIncident.location }}</span>
                </p>
                <p class="mb-2">
                  Date:
                  <span class="text-primary ml-4">
                    {{ getCurrentIncident.date }}
                    {{ getCurrentIncident.time }}
                    {{ getCurrentIncident.time_zone }}
                  </span>
                </p>
              </div>
              <div class="col-lg-7 button-items">
                <div class="float-end">
                  <b-button class="btn-label float-right" variant="secondary"
                            @click="goToCommandBoard">
                    <i class="bx bx-clipboard label-icon"></i>Command Board
                  </b-button>
                  <b-button class="btn-label" variant="primary">
                    <i class="bx bx-copy label-icon"></i>Copy To Preplan
                  </b-button>
                  <b-button class="btn-label" variant="danger" @click="archiveThisIncident">
                    <i class="bx bx-window-close label-icon"></i>Close & Archive Incident
                  </b-button>
                  <b-button class="btn-label" variant="danger" @click="cancelChanges">
                    <i class="bx bx-block label-icon"></i>Cancel
                  </b-button>
                  <b-button class="btn-label" variant="success" @click="saveIncident">
                    <i class="bx bx-cloud-upload label-icon"></i>Save
                  </b-button>
                </div>
              </div>
            </div>
            <hr/>
            <div>
              <b-tabs content-class="mt-4" justified pills>
                <b-tab active title="Demographics">
                  <div class="row">
                    <div class="col-6">
                      <IncidentForm/>
                    </div>
                    <div class="col-6">
                      <IncidentMap/>
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Triggers">
                  <IncidentTriggers/>
                </b-tab>

                <b-tab title="Checklists">
                  <IncidentChecklists/>
                </b-tab>

                <b-tab title="Subjects">
                  <IncidentSubjects/>
                </b-tab>

                <b-tab title="Files">
                  <IncidentFiles/>
                </b-tab>

                <b-tab title="eICS">
                  <IncidentEics/>
                </b-tab>

                <b-tab title="Notes">
                  <IncidentNotes/>
                </b-tab>

                <b-tab title="Log">
                  <IncidentLog/>
                </b-tab>

                <b-tab title="Tags">
                  <IncidentTags/>
                </b-tab>

                <b-tab title="Admin">
                  <IncidentAdmin/>
                </b-tab>

              </b-tabs>
            </div>

          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
import Layout from '@/router/layouts/main';
import IncidentForm from '@/components/incidents/incident-form';
import IncidentMap from '@/components/incidents/incident-map';
import IncidentTriggers from '@/components/incidents/incident-triggers.vue';
import IncidentChecklists from '@/components/incidents/incident-checklists.vue';
import IncidentSubjects from '@/components/incidents/incident-subjects.vue';
import IncidentFiles from '@/components/incidents/incident-files.vue';
import IncidentEics from '@/components/incidents/incident-eics.vue';
import IncidentNotes from '@/components/incidents/incident-notes.vue';
import IncidentLog from '@/components/incidents/incident-log.vue';
import IncidentTags from '@/components/incidents/incident-tags.vue';
import IncidentAdmin from '@/components/incidents/incident-admin.vue';
import {
  authComputed,
  incidentsComputed,
  incidentsMethods,
  checklistsMethods,
  filesMethods,
  tagsMethods,
} from '@/state/helpers';
import {
  showLoading,
  closeLoading,
} from '@/components/widgets/swalUtils';

export default {
  name: 'IncidentDetails',
  components: {
    Layout,
    IncidentForm,
    IncidentMap,
    IncidentTriggers,
    IncidentChecklists,
    IncidentSubjects,
    IncidentFiles,
    IncidentEics,
    IncidentNotes,
    IncidentLog,
    IncidentTags,
    IncidentAdmin,
  },
  data() {
    return {
      incident_id: 0,
      incident_tags: [],
    };
  },
  computed: {
    ...authComputed,
    ...incidentsComputed,
  },
  methods: {
    ...incidentsMethods,
    ...checklistsMethods,
    ...filesMethods,
    ...tagsMethods,
    async saveIncident() {
      // Update Basic Incident Data and Triggers
      await this.updateIncident({
        user: JSON.parse(this.currentUser),
        incident_id: this.incident_id,
      });

      // Update checklists to backend (API patch and delete)
      await this.updateIncidentChecklists({
        user: JSON.parse(this.currentUser),
        incident_id: this.incident_id,
      });

      // Update Files to backend (API patch and delete)
      await this.updateIncidentFiles({
        user: JSON.parse(this.currentUser),
        incident_id: this.incident_id,
      });

      // Update Tags to backend (API patch and delete)
      await this.updateIncidentTags({
        user: JSON.parse(this.currentUser),
        incident_id: this.incident_id,
      });
    },
    archiveThisIncident() {
      this.archiveIncident({
        user: JSON.parse(this.currentUser),
        incident_id: this.incident_id,
      }).then(() => {
        this.$router.push({ name: 'Active Incidents' });
        this.updateCurrentIncident(null);
      });
    },
    cancelChanges() {
      // Go back to incident listings
      this.$router.push({ name: 'Active Incidents' });
      this.updateCurrentIncident(null);
    },
    goToCommandBoard() {
      // Go to Command Board
      this.$router.push({ name: 'Command Board' });
    },
  },
  created() {
    const { incidentId } = this.$route.params;
    this.incident_id = +incidentId;

    const previousRoute = this.$router.options.history.state.back?.split('/')[2];
    // Find incident in the store listings
    showLoading('Loading incident...');
    let myInc;
    switch (previousRoute) {
      case 'active':
        myInc = this.getActiveIncidents?.find((inc) => inc.id === this.incident_id);
        break;
      case 'preplan':
        myInc = this.getPreplanIncidents?.find((inc) => inc.id === this.incident_id);
        break;
      case 'archived':
        myInc = this.getArchivedIncidents?.find((inc) => inc.id === this.incident_id);
        break;
      default:
        // Added for further debugging.
        console.log('No case found for previous Route.');
    }

    // Set to Current Incident at store.
    if (myInc && typeof myInc.coordinates !== 'undefined') {
      this.setCurrentIncident(myInc);
    } else {
      this.loadIncident({
        user: JSON.parse(this.currentUser),
        incident_id: this.incident_id,
      });
    }
    closeLoading();
  },
};
</script>

<style scoped>

</style>
